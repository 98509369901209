// const baseUrl = "http://localhost:8888/api-datacom/";
const baseUrl = "https://api.datacomlb.com/";

async function axiosPost(functionName, data, headers) {
  try {
    const Axios = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: headers
          ? headers.token
            ? `Bearer ${headers.token}`
            : null
          : null,
        "X-Username": headers
          ? headers.username
            ? headers.username
            : null
          : null,
        "X-Unid": getCookie("uuid"),
        "X-Type": headers ? (headers.utype ? headers.utype : null) : null,
      },
    });
    const response = await Axios.post(functionName, data);
    // if (response.data.returnCode.includes('0x0002')) return signout();
    return response.data.data
      ? JSON.parse(decryptValues(response.data.data))
      : response.data;
  } catch (error) {
    if (error.response) {
    } else if (error.request) {
    } else {
    }
  }
}

async function axiosGet(functionName, extraData, headers) {
  let url = "";
  if (!extraData) {
    url = `${functionName}`;
  } else {
    url = `${functionName}?${extraData}`;
  }
  const Axios = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: headers
        ? headers.token
          ? `Bearer ${headers.token}`
          : null
        : null,
      "X-Username": headers
        ? headers.username
          ? headers.username
          : null
        : null,
      "X-Unid": getCookie("uuid"),
      "X-Type": headers ? (headers.utype ? headers.utype : null) : null,
    },
  });

  try {
    let response = await Axios.get(url);
    // if (response.data.returnCode.includes('0x0002')) return signout();
    return response.data.data
      ? JSON.parse(decryptValues(response.data.data))
      : response.data;
  } catch (error) {
    if (error.response) {
    } else if (error.request) {
    } else {
    }
  }
}
